import { RecursiveAllValues } from '../types/general-types';

export type TRoutePath = RecursiveAllValues<typeof ROUTE_PATHS>;

export const ROUTE_PATHS = {
  auth: {
    login: '/login',
    loginDev: '/login-dev',
  },
  forms: {
    list: '/form',
    formBuilder: {
      base: '/form/form-builder',
      settings: '/form/form-builder/settings',
      questions: '/form/form-builder/questions',
    },
    viewForm: '/form/:formId',
  },
  dashboard: '/dashboard',
  userAssociation: '/user-association',
  screenPopUp: {
    screenPopUp: '/screen-popups',
    screenPopUpCta: '/screen-popups/cta',
    screenPopUpAutomatic: '/screen-popups/automatic',
    create: '/screen-popups/create',
    edit: '/screen-popups/:id/edit',
    getEditURL: (id: string) => {
      return `/screen-popups/${id}/edit`;
    },
  },
  agentStats: {
    agentStats: '/agent-stats',
    ccpStatuses: '/agent-stats/ccp-statuses',
    hierarchyStats: '/agent-stats/hierarchy-stats',
    queueStats: '/agent-stats/queue-stats',
  },
  holidayCalendar: {
    holidayCalendar: '/holiday-calendar',
    create: '/create',
    edit: '/:id/edit',
    getEditURL: (id: string) => {
      return `/${id}/edit`;
    },
  },
  graphConfiguration: {
    graphConfiguration: '/graph-configuration',
    create: '/create',
    edit: '/:id/edit',
    getEditURL: (id: string) => {
      return `/${id}/edit`;
    },
  },
  chatAttachmentManagement: {
    chatAttachmentManagement: '/chat-attachment-management',
    create: '/chat-attachment-management/create',
  },
  outboundCampaign: {
    outboundCampaign: '/outbound-campaign',
    upload: '/outbound-campaign/upload',
    edit: '/outbound-campaign/:id/edit',
    getEditURL: (id: string) => {
      return `/outbound-campaign/${id}/edit`;
    },
    fileSetup: '/outbound-campaign/:id/file-setup',
    getFileSetupURL: (id: string) => {
      return `/outbound-campaign/${id}/file-setup`;
    },
  },
} as const;
