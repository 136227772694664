import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  TDeleteFormModal,
  TDeleteUserAssociationModal,
  TModal,
  TModalReducer,
  TUpdateQueueModal,
  TDeleteHolidayCalendarModal,
  TDeleteChatAttachmentModal,
  TDeleteOutboundCampaignModal,
} from '../../app/types/modalType';

export const modalReducerInitialState: TModalReducer = {
  form: {
    deleteForm: {
      open: false,
    },
    updateQueue: {
      open: false,
    },
  },
  userAssociation: {
    deleteUserAssociation: {
      open: false,
    },
  },
  holidayCalendar: {
    deleteHolidayCalendar: {
      open: false,
    },
  },
  chatAttachmentManagement: {
    deleteChatAttachment: {
      open: false,
    },
  },
  outboundCampaignManager: {
    deleteOutboundCampaign: {
      open: false,
    },
    invalidPhoneNumbers: {
      open: false,
    },
  },
};

const modalReducer = createSlice({
  name: 'modalReducer',
  initialState: modalReducerInitialState,
  reducers: {
    setDeleteForm: (
      state,
      { payload }: PayloadAction<TModal<TDeleteFormModal>>
    ) => {
      state.form.deleteForm = payload;
    },
    setUpdateQueue: (
      state,
      { payload }: PayloadAction<TModal<TUpdateQueueModal>>
    ) => {
      state.form.updateQueue = payload;
    },
    setDeleteUserAssociation: (
      state,
      { payload }: PayloadAction<TModal<TDeleteUserAssociationModal>>
    ) => {
      state.userAssociation.deleteUserAssociation = payload;
    },
    setDeleteHolidayCalendar: (
      state,
      { payload }: PayloadAction<TModal<TDeleteHolidayCalendarModal>>
    ) => {
      state.holidayCalendar.deleteHolidayCalendar = payload;
    },
    setDeleteChatAttachment: (
      state,
      { payload }: PayloadAction<TModal<TDeleteChatAttachmentModal>>
    ) => {
      state.chatAttachmentManagement.deleteChatAttachment = payload;
    },
    setDeleteOutboundCampaign: (
      state,
      { payload }: PayloadAction<TModal<TDeleteOutboundCampaignModal>>
    ) => {
      state.outboundCampaignManager.deleteOutboundCampaign = payload;
    },
    setInvalidPhoneNumbers: (
      state,
      { payload }: PayloadAction<TModal>
     ) => {
        state.outboundCampaignManager.invalidPhoneNumbers = payload;
      },
  },
});

export const modalReducerActions = modalReducer.actions;

export default modalReducer.reducer;
