import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  navLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  activeNavlink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '& > div': {
      backgroundColor: `${theme.palette.secondary.main}33`,
    },
  },
  listItem: {
    '& > .MuiListItemIcon-root': {
      padding: '0 2.1rem 0 1.1rem',
    },

    '& > * > span': {
      fontWeight: 500,
    },

    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main}70`,
    },
  },
}));
