import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  TFormTypeReducerState } from '../../app/types/formTypes';

export const formTypeReducerInitialState: TFormTypeReducerState = {
  typeList: [],
};

const formTypeReducer = createSlice({
  name: 'formTypeReducer',
  initialState: formTypeReducerInitialState,
  reducers: {
    setFormTypeList: (
      state,
      { payload }: PayloadAction<TFormTypeReducerState>
    ) => payload,
  },
});

export const formTypeActions = formTypeReducer.actions;

export default formTypeReducer.reducer;
