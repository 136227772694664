import { utcToZonedTime, format } from 'date-fns-tz';
import dateFormat from 'date-fns/format';

const STANDARD_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
const DATE_FORMAT = 'dd.MM.yyyy';
export const BACKEND_DATE_FORMAT_YYYY_MM_DD = 'yyyy-MM-dd';

/**
 * Proxy for date-fns format method, in order to perform appropriate translation for dates
 * @param date
 * @param format
 */
export const formatDateWithTimezone = (date: Date | number) => {
  const zonedDate = utcToZonedTime(
    date,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  zonedDate.setUTCHours(0, 0, 0, 0);
  return format(zonedDate, STANDARD_DATE_FORMAT);
};

export const formatDate = (
  date: Date | number | null,
  format = DATE_FORMAT
) => {
  if (date === null) {
    return null;
  }
  return dateFormat(date, format);
};

export const getDatePlusXDays = (inputDate: Date, daysToAdd: number) => {
  const date = new Date(inputDate);
  date.setDate(date.getDate() + daysToAdd);
  return date;
};

export const getDateMinusXDays = (inputDate: Date, daysToSubstract: number) => {
  const date = new Date(inputDate);
  date.setDate(date.getDate() - daysToSubstract);
  return date;
};

export const setTimeToZero = (inputDate: Date) => {
  const date = new Date(inputDate);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getDistanceInDays = (inputDate1: Date, inputDate2: Date) => {
  const differenceInTime = inputDate2.getTime() - inputDate1.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays;
};
