import { notificationActions } from "../../../store/reducers/notificationReducer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { usersApi } from "../../api/users-api";


const useGetAdmins = (): string[] => {
  const [adminsList, setAdminsList] = useState<string[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    usersApi.getAdmins().then((response) => {
        setAdminsList(response.data);
      })
      .catch((error) =>
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'error',
            message: error?.response?.data.message,
          })
        )
      );
  }, []);

  return adminsList;
};

export default useGetAdmins;
