export const CHAT_ATTACHMENT_LIST_HEADER = [
  { label: 'chatAttachments.name', property: 'NAME' },
  { label: 'chatAttachments.title', property: 'TITLE' },
  { label: 'chatAttachments.size', property: 'SIZE' },
  { label: 'chatAttachments.uploadDate', property: 'UPLOAD_DATE' },
  { label: 'chatAttachments.userName', property: 'ADMIN' },
  { label: 'chatAttachments.description' },
  { label: 'chatAttachments.actions', align: 'center' as const },
  // { label: 'form.actions', align: 'center' as const },
];
