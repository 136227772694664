import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TSortList } from "../../app/types/general-types";
import { TGraphConfigurationFilter, TGraphConfigurationReducerState } from "../../app/types/graph-configuration-types";

export const graphConfigurationInitialState: TGraphConfigurationReducerState = {
  filters: {},
  forceRefresh: true,
  page: 0,
  sortingOptions: {direction: 'ASC', property: 'END_DATE'},
};

const graphConfigurationReducer = createSlice({
  name: 'graphConfigurationReducer',
  initialState: graphConfigurationInitialState,
  reducers: {
    setFilters: (state, {payload}: PayloadAction<Partial<TGraphConfigurationFilter>>) => {
      state.filters = payload;
    },
    setSorting: (state, {payload}: PayloadAction<TSortList>) => {
      state.sortingOptions = payload;
      state.forceRefresh = !state.forceRefresh;
      state.page = 0;
    },
    setPage: (state, {payload}: PayloadAction<number>) => {
      state.page = payload;
    },
  }
});

export const graphConfigurationActions = graphConfigurationReducer.actions;

export default graphConfigurationReducer.reducer;
