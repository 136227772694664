import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { THierarchyGroupReducerState } from '../../app/types/connect-types';

export const hierarchyGroupsInitialState: THierarchyGroupReducerState = {
  childGroups: [],
  groupName: '',
  hierarchyLevel: '',
  id: '',
};

const hierarchyGroupsReducer = createSlice({
  name: 'hierarchyGroupsReducer',
  initialState: hierarchyGroupsInitialState,
  reducers: {
    setHierarchyGroups: (
      state,
      { payload }: PayloadAction<THierarchyGroupReducerState>
    ) => payload,
  },
});

export const hierarchyGroupsActions = hierarchyGroupsReducer.actions;

export default hierarchyGroupsReducer.reducer;
