import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { STYLE_CONSTANTS } from '../../../../assets/styles/defaultTheme/constants';

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    backgroundColor: theme.palette.common.white,

    '&.Mui-focused > div': {
      backgroundColor: 'transparent',
    },
    '&.Mui-disabled': {
      backgroundColor: `${theme.palette.primary.main}12`,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-disabled > input': {
      cursor: 'not-allowed',

      '&::placeholder': {
        color: theme.palette.primary.main,
        fontWeight: 500,
        opacity: 1,
      },
    },
  },
  selectEmptyPlaceholderText: {
    color: '#A2B2B6',
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      color: theme.palette.text.primary,

      '& > div > span': {
        fontSize: '1rem',
      },

      '&.Mui-selected': {
        backgroundColor: `${theme.palette.secondary.main}25`,
      },

      '&:hover': {
        backgroundColor: `${theme.palette.secondary.main}25`,
      },
    },
  },
  menuItemAll: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    '&.MuiMenuItem-root': {
      position: 'sticky',
      top: 0,
      zIndex: 10,

      '& > div > span': {
        fontSize: '1rem',
      },
    },
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      color: theme.palette.primary.main,
      '&.Mui-checked': {
        color: theme.palette.primary.main,
      },
    },
  },
  fixedInput: {
    backgroundColor: theme.palette.common.white,
    height: '2.8125rem',

    '&.Mui-focused > div': {
      backgroundColor: 'transparent',
    },

    '& > div > div.MuiListItemText-root > span': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  selectWrapper: {
    ...STYLE_CONSTANTS.FLEX.COLUMN,
  },
}));

const ITEM_HEIGHT = 72;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
