import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    padding: '2rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.3rem',
    backgroundColor: theme.palette.common.white,
    textAlign: 'center',
    width: '50rem',
  },
  deleteModalWrapper: {
    border: `.13rem solid ${theme.palette.error.main}`,
  },
  button: {
    '&.MuiButton-root': {
      maxHeight: '2rem',
      margin: '0 2rem',
      padding: '1.5rem 2.7rem',
      fontWeight: 600,
    },
  },
  primaryButton: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      border: `.1rem solid ${theme.palette.primary.main}`,

      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        border: `.1rem solid ${theme.palette.secondary.main}`,
        color: theme.palette.primary.main,
      },
    },
  },
  secondaryButton: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      border: `.1rem solid ${theme.palette.secondary.main}`,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        border: `.1rem solid ${theme.palette.primary.main}`,
      },

      '&:disabled': {
        opacity: 0.5,
      },
    },
  },
  errorButton: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      border: `.1rem solid ${theme.palette.error.main}`,

      '&:hover': {
        backgroundColor: `${theme.palette.error.main}30`,
        color: theme.palette.error.main,
      },
    },
  },
  updateModalWrapper: {
    border: `.13rem solid ${theme.palette.primary.main}`,
  },
}));
