import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { NotFoundIcon } from '../../../../assets/images';

import { useNotFoundStyles } from './NoDataFound-style';

interface IProps {
  label?: string;
}

const NoDataFound: FC<IProps> = ({ label = 'noDataFound' }) => {
  const { t } = useTranslation();
  const { notFoundWrapper } = useNotFoundStyles();

  return (
    <Box className={notFoundWrapper}>
      <NotFoundIcon />
      <Typography component="span">{t(label)}</Typography>
    </Box>
  );
};

export default NoDataFound;
