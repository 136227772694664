import { useEffect, useState } from 'react';
import JwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { authActions } from '../../store/reducers/authReducer';
import storageUtils from '../../utils/storageUtils';
import { authApi } from '../api/auth-api';
import { TAuthInfo } from '../types/authTypes';

type TReturn = {
  login: (username: string, password: string) => void;
  logout: () => Promise<void>;
  error: string;
};

export const useAuth = (): TReturn => {
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (window.location.hash) {
      const authToken = window.location.hash.split('#').pop();
      authToken && handleLogin(authToken);
    }
  }, [window.location.hash]);

  const login = async (username: string, password: string): Promise<void> => {
    try {
      const {
        data: { accessToken },
      } = await authApi.login(username, password);
      handleLogin(accessToken);
      setError('');
    } catch (error: any) {
      if (error && error.response && error.response.status >= 400) {
        setError(t('error.invalidCredentials'));
      }
    }
  };

  const handleLogin = (token: string): void => {
    if (
      token.startsWith('401') ||
      token.startsWith('403') ||
      token.startsWith('500')
    ) {
      setError(t(`error.login.${token}`));
      return;
    }

    const userInfo = JwtDecode(token) as TAuthInfo;
    storageUtils.setAuthToken(token);
    storageUtils.setLocalStorageValue('language', userInfo.language || 'en');

    dispatch(authActions.login(userInfo));
  };

  const logout = async (): Promise<void> => {
    try {
      await authApi.logout();

      storageUtils.clearLocalUserData();
      dispatch(authActions.logout());
    } catch (error) {
      console.log(error);
    }
  };

  return { login, logout, error };
};
