import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TFormFilterRequest, TFormFilterReducerState } from "../../app/types/formTypes";
import { TSortList } from "../../app/types/general-types";

export const formFilterInitialState: TFormFilterReducerState = {
  filters: {
    createdAfter: null,
    createdBefore: null,
  },
  forceRefresh: true,
  page: 0,
  sortingOptions: { direction: 'ASC', property: 'CREATED'},
}

const formFilterReducer = createSlice ({
  name: 'filterReducer',
  initialState: formFilterInitialState,
  reducers: {
    setFilters: (
      state,
       { payload }: PayloadAction<Partial<TFormFilterRequest>>
    ) => {
      state.filters = payload;
      state.forceRefresh = !state.forceRefresh;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSorting: (state, { payload }: PayloadAction<TSortList>) => {
      state.sortingOptions = payload;
      state.forceRefresh = !state.forceRefresh;
      state.page = 0;
    },
  },
});

export const formFilterActions = formFilterReducer.actions;

export default formFilterReducer.reducer;
