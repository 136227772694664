import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  TFormStatusReducerState } from '../../app/types/formTypes';

export const formStatusReducerInitialState: TFormStatusReducerState = {
  statusList: [],
};

const formStatusReducer = createSlice({
  name: 'formStatusReducer',
  initialState: formStatusReducerInitialState,
  reducers: {
    setFormStatusList: (
      state,
      { payload }: PayloadAction<TFormStatusReducerState>
    ) => payload,
  },
});

export const formStatusActions = formStatusReducer.actions;

export default formStatusReducer.reducer;
