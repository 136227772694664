import { IStore } from '..';
import {
  TUserAssociationFilterRequest,
  TUserAssociationFilterReducerState,
} from '../../app/types/user-association-types';

export const getFilters = (
  state: IStore
): Partial<TUserAssociationFilterRequest> =>
  state.userAssociationFilterReducer.filters;
export const getState = (state: IStore): TUserAssociationFilterReducerState =>
  state.userAssociationFilterReducer;
