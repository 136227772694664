import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { STYLE_CONSTANTS } from '../../../../assets/styles/defaultTheme/constants';

export const useLoaderStyles = makeStyles((theme: Theme) => ({
  loaderWrapper: {
    ...STYLE_CONSTANTS.FLEX.COLUMN,
    alignItems: 'center',
    textAlign: 'center',
    margin: '.5rem 0',

    '& > span': {
      fontSize: '1.2rem',
      fontWeight: 500,
      maxHeight: '5rem',
      margin: '.5rem',
    },
  },
}));
