import Cookies from 'js-cookie';

export const storageKeys = {
  authToken: 'authToken',
  language: 'language',
} as const;

interface ILocalStorage {
  [storageKeys.authToken]: string;
  [storageKeys.language]: string;
}

const defaultValues: ILocalStorage = {
  authToken: '',
  language: 'en',
};

type localStorageKey = typeof storageKeys[keyof typeof storageKeys];

/**
 * Retrieves value stored locally for {@param key} or returns default value
 *
 * @param key
 * @param defaultValue
 * @param typeCast
 */
const getLocalStorageValue = <K extends localStorageKey, T = ILocalStorage[K]>(
  key: K,
  defaultValue: T = defaultValues[key] as unknown as T,
  typeCast = 'string'
): T => {
  try {
    const item = localStorage.getItem(key);
    const value = typeCast === 'string' ? item : JSON.parse(item || '');
    if (value != null && typeof value === typeCast) {
      return value as T;
    }
  } catch (e) {
    // do nothing
  }

  return defaultValue;
};

const getAuthToken = () => {
  return Cookies.get('authToken') ?? '';
};

/**
 * Updates value for {@param key} on local storage
 * @param key
 * @param value
 */
const setLocalStorageValue = <
  K extends localStorageKey,
  T = typeof defaultValues[K]
>(
  key: K,
  value: T
): void => {
  localStorage.setItem(
    key,
    typeof value === 'string' ? value : JSON.stringify(value)
  );
};

const setAuthToken = (authToken: string) => {
  Cookies.set('authToken', authToken, {
    domain: window.location.hostname.split('.').slice(1).join('.'),
    expires: 0.5,
  });
};

/**
 * Removes provided key from storage
 * @param key
 */
const removeLocalStorageValue = <K extends localStorageKey>(key: K): void => {
  localStorage.removeItem(key);
};

/**
 * Clears all user data from the local storage
 */
const clearLocalUserData = (): void => {
  Cookies.remove('authToken', {
    domain: window.location.hostname.split('.').slice(1).join('.'),
  });
};

export default {
  getLocalStorageValue,
  removeLocalStorageValue,
  setLocalStorageValue,
  clearLocalUserData,
  getAuthToken,
  setAuthToken,
};
