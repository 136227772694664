import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';

import notificationReducer, {
  notificationReducerInitialState,
} from './reducers/notificationReducer';
import authReducer, { authReducerInitialState } from './reducers/authReducer';
import formStatusReducer, {
  formStatusReducerInitialState,
} from './reducers/formStatusReducer';
import modalReducer, {
  modalReducerInitialState,
} from './reducers/modalReducer';
import configReducer, {
  configReducerInitialState,
} from './reducers/configReducer';
import formFilterReducer, {
  formFilterInitialState,
} from './reducers/formFilterReducer';
import userAssociationFilterReducer, {
  userAssociationFilterInitialState,
} from './reducers/user-association-filter-reducer';
import languageReducer, {
  languageReducerInitialState,
} from './reducers/language-reducer';
import holidayCalendarReducer, {
  holidayCalendarInitialState,
} from './reducers/holiday-calendar-reducer';
import hierarchyGroupsReducer, {
  hierarchyGroupsInitialState,
} from './reducers/hierarchy-groups-reducer';
import graphConfigurationReducer, {
  graphConfigurationInitialState,
} from './reducers/graph-configuration-reducer';
import chatAttachmentFilterReducer, {
  chatAttachmentFilterInitialState,
} from './reducers/chat-attachment-filter-reducer';
import formTypeReducer, { formTypeReducerInitialState } from './reducers/formTypeReducer';
import outboundCampaignManagerReducer, { outboundCampaignManagerInitialState } from './reducers/outbound-campaign-manager-reducer';
import fileStatusReducer, { fileStatusReducerInitialState } from './reducers/fileStatusReducer';

export const globalInitialState: IStore = {
  notificationReducer: notificationReducerInitialState,
  authReducer: authReducerInitialState,
  formStatusReducer: formStatusReducerInitialState,
  formTypeReducer: formTypeReducerInitialState,
  modalReducer: modalReducerInitialState,
  configReducer: configReducerInitialState,
  formFilterReducer: formFilterInitialState,
  userAssociationFilterReducer: userAssociationFilterInitialState,
  languageReducer: languageReducerInitialState,
  holidayCalendarReducer: holidayCalendarInitialState,
  hierarchyGroupsReducer: hierarchyGroupsInitialState,
  graphConfigurationReducer: graphConfigurationInitialState,
  chatAttachmentFilterReducer: chatAttachmentFilterInitialState,
  outboundCampaignManagerReducer: outboundCampaignManagerInitialState,
  fileStatusReducer: fileStatusReducerInitialState,
};

const store = configureStore({
  reducer: combineReducers({
    notificationReducer,
    authReducer,
    formStatusReducer,
    formTypeReducer,
    modalReducer,
    configReducer,
    formFilterReducer,
    userAssociationFilterReducer,
    languageReducer,
    holidayCalendarReducer,
    hierarchyGroupsReducer,
    graphConfigurationReducer,
    chatAttachmentFilterReducer,
    outboundCampaignManagerReducer,
    fileStatusReducer,
  }),
});

export default store;

export type IStore = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, IStore, null, Action<string>>;
