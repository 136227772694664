import { FC, ReactNode } from 'react';

import { TableCell, TableRow } from '@mui/material';

import NoDataFound from '../../shared/components/NoDataFound/NoDataFound';
import LoaderIcon from '../../shared/components/loader/LoaderIcon';

interface IProps {
  noResult?: boolean;
  children: ReactNode;
  isLoading?: boolean;
  length: number;
}

/**
 * This component is a wrapper which renders conditionally
 * @param noResult - if it's true then will render the no results span
 * @param isLoading
 * @param length - for styling
 * @param children
 */

const ListBodyWrapper: FC<IProps> = ({
  noResult = false,
  isLoading = false,
  length,
  children,
}) => {
  if (isLoading) {
    return (
      <TableRow>
        <TableCell colSpan={length}>
          <LoaderIcon />
        </TableCell>
      </TableRow>
    );
  }
  if (noResult) {
    return (
      <TableRow>
        <TableCell colSpan={length}>
          <NoDataFound />
        </TableCell>
      </TableRow>
    );
  }

  return <>{children}</>;
};

export default ListBodyWrapper;
