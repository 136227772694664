import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TSortList } from "../../app/types/general-types";
import { TOutboundCampaignFilterRequest, TOutboundCampaignReducerState } from "../../app/types/outbound-campaign-manager-types";

export const outboundCampaignManagerInitialState : TOutboundCampaignReducerState = {
  filters: {},
  forceRefresh: true,
  page: 0,
  sortingOptions: { direction: 'ASC', property: 'UPLOAD_DATE' },
};

const outboundCampaignManagerReducer = createSlice({
  name: "outboundCampaignManagerReducer",
  initialState: outboundCampaignManagerInitialState,
  reducers: {
    setFilters: (
      state, 
      { payload }: PayloadAction<Partial<TOutboundCampaignFilterRequest>>
      ) => {
      state.filters = payload;
      state.forceRefresh = !state.forceRefresh
    },
    setSorting: (state, { payload }: PayloadAction<TSortList>) => {
      state.sortingOptions = payload;
      state.forceRefresh = !state.forceRefresh;
      state.page = 0;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
  }
});

export const outboundCampaignManagerActions = outboundCampaignManagerReducer.actions;

export default outboundCampaignManagerReducer.reducer;