import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TAuthInfo, TAuthReducer } from '../../app/types/authTypes';
import JwtDecode from 'jwt-decode';
import storageUtils from '../../utils/storageUtils';

const token = storageUtils.getAuthToken();
const userInfoFromToken = token ? JwtDecode(token) : null;

export const authReducerInitialState: TAuthReducer = {
  isLogged: !!userInfoFromToken,
  userInfo: userInfoFromToken as TAuthInfo,
};

const authReducer = createSlice({
  name: 'authReducer',
  initialState: authReducerInitialState,
  reducers: {
    login: (state, { payload }: PayloadAction<TAuthInfo>) => {
      state.isLogged = true;
      state.userInfo = payload;
    },
    logout: (state) => {
      state.isLogged = false;
      state.userInfo = null;
    },
  },
});

export const authActions = authReducer.actions;

export default authReducer.reducer;
