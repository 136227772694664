import { FC } from 'react';

import { Box } from '@mui/material';

import ChatAttachmentManagementListing from '../components/chat-attachment-management/chat-attachment-listing';
import ChatAttachmentManagementFilters from '../components/chat-attachment-management/chat-attachment-filters';
import CreateChatAttachment from '../components/chat-attachment-management/create-chat-attachment';

import { useStyles } from '../components/forms/FormFilters-style';

const ChatAttachmentManagement: FC = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.filterSectionWrapper}>
        <ChatAttachmentManagementFilters />
        <CreateChatAttachment />
      </Box>
      <ChatAttachmentManagementListing />
    </>
  );
};

export default ChatAttachmentManagement;
