import axios from 'axios';

import storageUtils from '../../utils/storageUtils';
import { ROUTE_PATHS } from '../routes/route-paths';

const baseURL: string = process.env.NX_BASE_URL || '';

export const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 300000,
});

axiosInstance.interceptors.request.use((config) => {
  const token = storageUtils.getAuthToken();

  config.headers = Object.assign(
    {
      Authorization: token,
    },
    config.headers
  );

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      storageUtils.clearLocalUserData();

      window.location.href = ROUTE_PATHS.auth.login;
    }

    return Promise.reject(error);
  }
);
