import { AxiosPromise } from 'axios';

import { axiosInstance } from './axios-client';
import apiPaths from './api-paths';

export const authApi: {
  login: (username: string, password: string) => AxiosPromise<{ accessToken: string }>;
  logout: () => AxiosPromise;
} = {
  login: (username, password) => axiosInstance.post(apiPaths.auth.login, { username, password }),
  logout: () => axiosInstance.post(apiPaths.auth.logout),
};
