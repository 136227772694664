import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { STYLE_CONSTANTS } from '../../../assets/styles/defaultTheme/constants';

export const useStyles = makeStyles((theme: Theme) => ({
  pagination: {
    ...STYLE_CONSTANTS.FLEX.SPACE_EVENLY,
    padding: '1rem',
  },
  header: {
    color: theme.palette.primary.main,
    padding: '1rem',
    fontWeight: 600,
    paddingBottom: '1rem',
    borderBottom: `1px solid ${theme.palette.primary.dark}25`,
  },
  listWrapper: {
    ...STYLE_CONSTANTS.DISPLAY.GENERIC_DISPLAY,
    width: '100%',
  },
  table: {
    '&.MuiTable-root': {
      width: 'calc(100% - 2rem)',
      margin: '0 1rem',

      '& > tbody > tr:hover': {
        backgroundColor: `${theme.palette.primary.main}25`,
      },

      '& > tbody > tr > td': {
        cursor: 'pointer',
        maxWidth: '2rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      
      '& > tbody > tr > td:last-of-type': {
        maxWidth: 'unset',
      },
    },
  },
}));
