import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { useStyles } from './FieldWrapper-style';

type TProps = {
  label?: string;
  percent?: number;
  sxPropsBox?: any;
};

const FieldWrapper: FC<TProps> = ({
  label = '',
  children,
  percent = 100,
  sxPropsBox = {},
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ percent });

  return (
    <Box className={classes.wrapper} sx={sxPropsBox}>
      <Typography variant="label" component="label">
        {t(label)}
      </Typography>
      {children}
    </Box>
  );
};

export default FieldWrapper;
