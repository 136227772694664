import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItem } from '@mui/material';

import { useAuth } from '../../hooks/useAuth';

const HeaderDropdownMenu: FC = () => {
  const { logout } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <MenuItem>Profile</MenuItem>
      <MenuItem>Settings</MenuItem>
      <MenuItem onClick={logout}>{t('logout.button')}</MenuItem>
    </>
  );
};

export default HeaderDropdownMenu;
