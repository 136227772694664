import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

import { TTableHeaderProps } from '../../types/tableTypes';

import { useStyles } from './List-style';

const ListHeader: FC<TTableHeaderProps> = ({
  order,
  orderBy,
  handleSort,
  headers,
}) => {
  const createSortHandler =
    (property: string) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleSort(event, property);
    };
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headers.map((header) => (
          <TableCell
            classes={{ root: classes.listHeaderCell }}
            key={header.label}
            sortDirection={orderBy === header.property ? order : false}
            {...(header.align && {
              align: header.align,
            })}
          >
            <TableSortLabel
              classes={{ root: classes.listSortLabel }}
              disabled={header.property === undefined}
              hideSortIcon={header.property === undefined}
              active={orderBy === header.property}
              direction={orderBy === header.property ? order : 'asc'}
              onClick={createSortHandler(header.property || '')}
            >
              {t(`${header.label}`)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default ListHeader;
