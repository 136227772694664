import { IStore } from '..';
import {
  TDeleteChatAttachmentModal,
  TDeleteFormModal,
  TDeleteHolidayCalendarModal,
  TDeleteOutboundCampaignModal,
  TDeleteUserAssociationModal,
  TModal,
  TUpdateQueueModal,
} from '../../app/types/modalType';

export const getFormDeleteModal = (state: IStore): TModal<TDeleteFormModal> =>
  state.modalReducer.form.deleteForm;
export const getFormUpdateQueueModal = (
  state: IStore
): TModal<TUpdateQueueModal> => state.modalReducer.form.updateQueue;
export const getUserAssociationDeleteModal = (
  state: IStore
): TModal<TDeleteUserAssociationModal> =>
  state.modalReducer.userAssociation.deleteUserAssociation;
export const getHolidayCalendarDeleteModal = (
  state: IStore
): TModal<TDeleteHolidayCalendarModal> =>
  state.modalReducer.holidayCalendar.deleteHolidayCalendar;
export const getChatAttachmentDeleteModal = (
  state: IStore
): TModal<TDeleteChatAttachmentModal> =>
  state.modalReducer.chatAttachmentManagement.deleteChatAttachment;
export const getOutboundCampaignDeleteModal = (
  state: IStore
): TModal<TDeleteOutboundCampaignModal> =>
  state.modalReducer.outboundCampaignManager.deleteOutboundCampaign;
export const getOutboundCampaignInvalidPhoneNumbers = (
  state: IStore
): TModal => 
  state.modalReducer.outboundCampaignManager.invalidPhoneNumbers