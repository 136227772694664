import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import DnsIcon from '@mui/icons-material/Dns';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachmentIcon from '@mui/icons-material/Attachment';
import PieChart from '@mui/icons-material/PieChart';
import CampaignIcon from '@mui/icons-material/Campaign';

import { ROUTE_PATHS } from '../../routes/route-paths';
import { useConfig } from '../../hooks/useConfig';

import { useStyles } from './navbar-list-style';
import { FC } from 'react';

interface INavBarList {
  open: boolean;
}

const NavbarList: FC<INavBarList> = ({ open }) => {
  const { activeNavlink, listItem, navLink } = useStyles();
  const location = useLocation().pathname;
  const { t } = useTranslation();
  let activeLinkScreenPopups = false;
  let activeLinkStats = false;

  const { env } = useConfig();

  if (
    location === '/screen-popups/cta' ||
    location === '/screen-popups/automatic'
  ) {
    activeLinkScreenPopups = true;
  } else {
    if (
      location === '/agent-stats/ccp-statuses' ||
      location === '/agent-stats/hierarchy-stats' ||
      location === '/agent-stats/queue-stats'
    ) {
      activeLinkStats = true;
    }
  }

  return (
    <List>
      {env?.enabledFeatures?.DASHBOARD && (
        <NavLink
          to={ROUTE_PATHS.dashboard}
          className={navLink}
          activeClassName={activeNavlink}
        >
          <ListItem button className={listItem}>
            <Tooltip
              disableFocusListener
              disableTouchListener
              placement="bottom"
              title={`${t('sideNav.dashboard')}`}
            >
              <ListItemIcon>
                <EqualizerIcon color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('sideNav.dashboard')} />
          </ListItem>
        </NavLink>
      )}
      <NavLink
        to={ROUTE_PATHS.forms.list}
        className={navLink}
        activeClassName={activeNavlink}
      >
        <ListItem button className={listItem}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            placement="bottom"
            title={`${t('sideNav.forms')}`}
          >
            <ListItemIcon>
              <DnsIcon color="primary" />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t('sideNav.forms')} />
        </ListItem>
      </NavLink>
      <NavLink
        to={ROUTE_PATHS.screenPopUp.screenPopUpCta}
        className={activeLinkScreenPopups ? activeNavlink : navLink}
      >
        <ListItem button className={listItem}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            placement="bottom"
            title={`${t('sideNav.screenPopUp')}`}
          >
            <ListItemIcon>
              <OpenInNewIcon color="primary" />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t('sideNav.screenPopUp')} />
        </ListItem>
      </NavLink>
      <NavLink
        to={ROUTE_PATHS.agentStats.ccpStatuses}
        className={activeLinkStats ? activeNavlink : navLink}
      >
        <ListItem button className={listItem}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            placement="bottom"
            title={`${t('sideNav.agentStats')}`}
          >
            <ListItemIcon>
              <DashboardIcon color="primary" />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={t('sideNav.agentStats')} />
        </ListItem>
      </NavLink>
      {env?.enabledFeatures?.USER_ASSOCIATION && (
        <NavLink
          to={ROUTE_PATHS.userAssociation}
          className={navLink}
          activeClassName={activeNavlink}
        >
          <ListItem button className={listItem}>
            <Tooltip
              disableFocusListener
              disableTouchListener
              placement="bottom"
              title={`${t('sideNav.userAssociation')}`}
            >
              <ListItemIcon>
                <GroupIcon color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('sideNav.userAssociation')} />
          </ListItem>
        </NavLink>
      )}
      {env?.enabledFeatures?.HOLIDAY_CALENDAR && (
        <NavLink
          to={ROUTE_PATHS.holidayCalendar.holidayCalendar}
          className={navLink}
          activeClassName={activeNavlink}
        >
          <ListItem
            button
            className={listItem}
            data-test-id={`holiday_calendar_item`}
          >
            <Tooltip
              disableFocusListener
              disableTouchListener
              placement="bottom"
              title={`${t('sideNav.holidayCalendar')}`}
            >
              <ListItemIcon>
                <CalendarMonthIcon color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('sideNav.holidayCalendar')} />
          </ListItem>
        </NavLink>
      )}
      {env?.enabledFeatures?.GRAPH_CONFIGURATION && (
        <NavLink
          to={ROUTE_PATHS.graphConfiguration.graphConfiguration}
          className={navLink}
          activeClassName={activeNavlink}
        >
          <ListItem button className={listItem} data-test-id={`graph_item`}>
            <Tooltip
              disableFocusListener
              disableTouchListener
              placement="bottom"
              title={`${t('sideNav.graphConfiguration')}`}
            >
              <ListItemIcon>
                <PieChart color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('sideNav.graphConfiguration')} />
          </ListItem>
        </NavLink>
      )}
      {env?.enabledFeatures?.CHAT_ATTACHMENT_MANAGEMENT && (
        <NavLink
          to={ROUTE_PATHS.chatAttachmentManagement.chatAttachmentManagement}
          className={navLink}
          activeClassName={activeNavlink}
        >
          <ListItem
            button
            className={listItem}
            data-test-id={`chat_attachment_management_item`}
          >
            <Tooltip
              disableFocusListener
              disableTouchListener
              placement="bottom"
              title={`${t('sideNav.chatAttachmentManagement')}`}
            >
              <ListItemIcon>
                <AttachmentIcon
                  color="primary"
                  sx={{
                    transform: 'rotate(90deg)',
                  }}
                />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary={t('sideNav.chatAttachmentManagement')} />
          </ListItem>
        </NavLink>
      )}
      {env?.enabledFeatures?.OUTBOUND_CAMPAIGN && (
        <NavLink
          to={ROUTE_PATHS.outboundCampaign.outboundCampaign}
          className={navLink}
          activeClassName={activeNavlink}
        >
          <ListItem
            button
            className={listItem}
            data-test-id={`outbound_campaign_manager_item`}
          >
            <Tooltip
              disableFocusListener
              disableTouchListener
              placement="bottom"
              title={`${t('sideNav.outboundCampaignManager')}`}
            >
              <ListItemIcon>
                <CampaignIcon color="primary" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              sx={{ whiteSpace: open ? 'pre-wrap' : 'nowrap' }}
              primary={t('sideNav.outboundCampaignManager')}
            />
          </ListItem>
        </NavLink>
      )}
    </List>
  );
};

export default NavbarList;
