import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './translation/translationEN.json';
import translationES from './translation/translationES.json';
import translationFR from './translation/translationFR.json';
import translationDE from './translation/translationDE.json';
import translationNL from './translation/translationNL.json';
import translationIT from './translation/translationIT.json';



export const resources: Resource = {
  us: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  nl: {
    translation: translationNL,
  },
  it: {
    translation: translationIT,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'us',
  fallbackLng: 'us',
  keySeparator: false,
  interpolation: { escapeValue: false },
});

export default i18n;
