import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Toast } from '@webhelp-aws-connect-frontend/toast';

import SideNav from './components/SideNav/side-nav';
import MainRoutes from './routes/main-routes';
import LoginRoutes from './routes/login-routes';

import { authSelectors, notificationSelectors } from '../store/selectors';
import { notificationActions } from '../store/reducers/notificationReducer';

import './app.css';

export const App: FC = () => {
  const isLogged = useSelector(authSelectors.getIsLogged);
  const notification = useSelector(notificationSelectors.getNotification);
  const dispatch = useDispatch();
  if (!isLogged) return <LoginRoutes />;

  return (
    <>
      <Toast
        {...notification}
        close={() => dispatch(notificationActions.closeNotification())}
      />
      <SideNav>
        <MainRoutes />
      </SideNav>
    </>
  );
};

export default App;
