import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TFileStatusReducerState } from '../../app/types/outbound-campaign-manager-types';

export const fileStatusReducerInitialState: TFileStatusReducerState = {
  statusList: [],
};

const fileStatusReducer = createSlice({
  name: 'fileStatusReducer',
  initialState: fileStatusReducerInitialState,
  reducers: {
    setFileStatusList: (
      state,
      { payload }: PayloadAction<TFileStatusReducerState>
    ) => payload,
  },
});

export const fileStatusActions = fileStatusReducer.actions;

export default fileStatusReducer.reducer;
