import { TSortList } from "../app/types/general-types";

export const sortOnClick = ({ direction, property }: TSortList, id: string): TSortList => {
  if (id === property && direction === 'ASC') {
    return {
      direction: 'DESC',
      property: id,
    };
  } else {
    return {
      direction: 'ASC',
      property: id,
    };
  }
};
