import { AxiosPromise } from "axios";
import apiPaths from "./api-paths";
import { axiosInstance } from "./axios-client";

const { users } = apiPaths;

export const usersApi: {
  getLanguage: () => AxiosPromise<string>;
  setLanguage: (language: string) => AxiosPromise
  getAdmins: () => AxiosPromise<string[]>;
} = {
  getLanguage: () => axiosInstance.get(users.users + users.language),
  setLanguage: (language) => axiosInstance.post(users.users + users.language, language),
  getAdmins: () => axiosInstance.get(users.users + users.admins)
};
