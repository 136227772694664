import { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './app/app';
import store from './store';
import './i18n';

import { ThemeProvider } from '@mui/material/styles';
import baseTheme from './assets/styles/defaultTheme/baseTheme';
import { CssBaseline } from '@mui/material';

render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={baseTheme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
