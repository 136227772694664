import { AxiosPromise } from 'axios';
import {
  TChatAttachmentElement,
  TChatAttachmentRequest,
  TCreateNewAttachment,
  TDeleteAttachment,
  TGetPresignedURLRequest,
} from '../types/chat-attachment-types';
import { TPagedSearch } from '../types/general-types';
import apiPaths from './api-paths';
import { axiosInstance } from './axios-client';

export const chatAttachmentManagementApi: {
  searchChatAttachment: (
    request: TChatAttachmentRequest
  ) => AxiosPromise<TPagedSearch<TChatAttachmentElement>>;
  getPresignedUrl: (request: TGetPresignedURLRequest) => AxiosPromise<string>;
  createNewAttachment: (request: TCreateNewAttachment) => AxiosPromise;
  deleteAttachment: (request: TDeleteAttachment) => AxiosPromise;
} = {
  searchChatAttachment: (request) =>
    axiosInstance.post(
      apiPaths.chatAttachments.base + apiPaths.chatAttachments.files,
      request
    ),
  getPresignedUrl: (request) =>
    axiosInstance.get(apiPaths.chatAttachments.base + '/' + request.fileName),
  createNewAttachment: (request) => {
    const formData = new FormData();
    formData.append('title', request.title);
    formData.append('description', request.description);
    formData.append('file', request.file);

    return axiosInstance.post(apiPaths.chatAttachments.base, formData);
  },
  deleteAttachment: (request) => {
    return axiosInstance.post(
      apiPaths.chatAttachments.base +
        apiPaths.chatAttachments.remove +
        '/' +
        request.id
    );
  },
};
