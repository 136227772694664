import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError } from 'axios';

import { Button, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { modalSelectors } from '../../../store/selectors';
import { notificationActions } from '../../../store/reducers/notificationReducer';
import { modalReducerActions } from '../../../store/reducers/modalReducer';

import { useStyles } from '../modals/delete-modal-styles';
import { chatAttachmentManagementApi } from '../../api/chat-attachment-management-api';

export interface IChatAttachmentDeleteModal {
  onSuccessfulDeletion?: () => void;
  attachmentName: string;
}

const ChatAttachmentDeleteModal = ({
  attachmentName,
  onSuccessfulDeletion,
}: IChatAttachmentDeleteModal) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { open, data: chatAttachment } = useSelector(
    modalSelectors.getChatAttachmentDeleteModal
  );

  const handleDelete = () => {
    setLoading(true);
    chatAttachmentManagementApi
      .deleteAttachment({ id: chatAttachment!.id! })
      .then(() => handleClose())
      .then(() => onSuccessfulDeletion?.())
      .then(() =>
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'success',
            message: t('chatAttachments.deleteModal.deleteConfirmation'),
          })
        )
      )
      .catch((err: AxiosError) =>
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'error',
            message: t('error.message') + (err?.response as any)?.data.message,
          })
        )
      )
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    dispatch(
      modalReducerActions.setDeleteChatAttachment({
        open: false,
        data: undefined,
      })
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={`${classes.modal} ${classes.deleteModalWrapper}`}>
        <Typography variant="h1" color="error.main">
          {t('chatAttachments.deleteModal.deleteHeader', {
            attachmentName: attachmentName,
          })}
        </Typography>
        <Typography
          component="p"
          sx={{
            margin: '2rem 0',
            fontSize: '1rem',
            fontWeight: 500,
          }}
        >
          {t('chatAttachments.deleteModal.deleteText')}
        </Typography>
        <Box>
          <Button
            classes={{ root: `${classes.button} ${classes.secondaryButton}` }}
            onClick={handleClose}
          >
            {t('button.close')}
          </Button>
          <Button
            classes={{ root: `${classes.button} ${classes.errorButton}` }}
            disabled={loading}
            onClick={handleDelete}
          >
            {t('button.delete')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChatAttachmentDeleteModal;
