import { UseFormRegisterReturn } from 'react-hook-form';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Box, FormHelperText, Typography } from '@mui/material';

import { TSelectOption } from '../../../types/general-types';

import { MenuProps, useStyles } from './select-style';

interface IProps<T = string> extends Partial<UseFormRegisterReturn> {
  label?: string;
  options?: TSelectOption<string, T>[];
  value?: T[];
  error?: boolean;
  helperText?: string;
  handleChange?: (values: T[]) => void;
  placeholder?: string;
  allValuesOption?: string;
  preselectAll?: boolean;
}

const Select = <T extends string | number>({
  label = '',
  options = [],
  value = [],
  placeholder,
  error = false,
  helperText,
  handleChange,
  allValuesOption,
  preselectAll = false,
  ...rest
}: IProps<T>) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const onChangeHandle = (event: SelectChangeEvent<T[]>): void => {
    const values = event.target.value as T[];

    if (values[values.length - 1] === 'ALL_OPTIONS') {
      if (values.length === options.length + 1) {
        handleChange && handleChange([]);
      } else {
        const allOptions = options.map((option) => option.value);
        handleChange && handleChange(allOptions);
      }
    } else {
      handleChange && handleChange(values);
    }
  };

  useEffect(() => {
    if (preselectAll) {
      const allOptions = options.map((option) => option.value);
      handleChange && handleChange(allOptions);
    }
  }, [options, preselectAll]);

  return (
    <Box className={classes.selectWrapper}>
      <Typography
        component="label"
        sx={{
          fontWeight: 600,
          display: 'block',
          marginBottom: '.5rem',
        }}
      >
        {label}
      </Typography>
      <FormControl>
        {options.length === 0 ? (
          <OutlinedInput
            className={classes.input}
            placeholder={placeholder}
            disabled
          />
        ) : (
          <MuiSelect
            {...rest}
            multiple
            displayEmpty
            value={value}
            error={error}
            onChange={onChangeHandle}
            input={<OutlinedInput className={classes.input} />}
            renderValue={(selected) => {
              if (selected.length === 0)
                return (
                  <Typography className={classes.selectEmptyPlaceholderText}>
                    {placeholder ?? t('formBuilder.settings.queuesPlaceholder')}
                  </Typography>
                );
              else if (selected.length === 1)
                return t('field.select.oneElementSelected');
              else
                return t('field.select.elementsSelected', {
                  count: (selected as T[]).length,
                });
            }}
            MenuProps={MenuProps}
          >
            {allValuesOption && (
              <MenuItem className={classes.menuItemAll} value="ALL_OPTIONS">
                <Checkbox
                  className={classes.checkbox}
                  checked={value.length === options.length}
                  size="small"
                />
                <ListItemText primary={allValuesOption} />
              </MenuItem>
            )}
            {options.map((option) => (
              <MenuItem
                className={classes.menuItem}
                value={option.value}
                key={uuid()}
              >
                <Checkbox
                  className={classes.checkbox}
                  checked={value.findIndex((el) => option.value === el) !== -1}
                  size="small"
                />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </MuiSelect>
        )}
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default Select;
