import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { STYLE_CONSTANTS } from '../../../assets/styles/defaultTheme/constants';

export const useStyles = makeStyles((theme: Theme) => ({
  filterWrapper: {
    ...STYLE_CONSTANTS.DISPLAY.GENERIC_DISPLAY,
    ...STYLE_CONSTANTS.FLEX.FLEX_WRAP,
    display: 'block',
    marginBottom: '.5rem',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: '1rem',

    '& > div': {
      width: '80%',
    },
  },
  button: {
    '&.MuiButton-root': {
      maxHeight: '2rem',
      fontWeight: 600,
      maxWidth: '2rem',
      fontSize: '.8rem',
      padding: '1.1rem 3.5rem',
      marginLeft: '.5rem',
      alignSelf: 'center',

      '&:disabled': {
        opacity: 0.5,
      },
    },
  },
  submitButton: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.secondary.main,
      border: `.1rem solid ${theme.palette.secondary.main}`,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        border: `.1rem solid ${theme.palette.primary.main}`,
      },
    },
  },
  resetButton: {
    '&.MuiButton-root': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      border: `.1rem solid ${theme.palette.primary.main}`,

      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        border: `.1rem solid ${theme.palette.secondary.main}`,
      },
    },
  },
  dateRangePicker: {
    display: 'flex',
  },
  header: {
    padding: '1rem',
    borderBottom: `1px solid ${theme.palette.primary.main}25`,
    marginBottom: '.5rem',
  },
  browseButton: {
    ...STYLE_CONSTANTS.DISPLAY.GENERIC_DISPLAY,
    ...STYLE_CONSTANTS.FLEX.COLUMN,
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: `${theme.palette.secondary.main}25`,
    flex: '1 0 calc(25% - 2rem)',
    padding: '1rem',
    fontWeight: 600,
    fontSize: '1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    border: `.1rem solid ${theme.palette.secondary.main}`,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      border: `.1rem solid ${theme.palette.primary.main}`,
    },
  },
}));
