import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { STYLE_CONSTANTS } from '../../../assets/styles/defaultTheme/constants';

export const useStyles = makeStyles((theme: Theme) => ({
  filterSectionWrapper: {
    ...STYLE_CONSTANTS.FLEX.SPACE_BETWEEN,
    marginBottom: '2rem',
  },
  filterWrapper: {
    ...STYLE_CONSTANTS.DISPLAY.GENERIC_DISPLAY,
    ...STYLE_CONSTANTS.FLEX.FLEX_WRAP,
    flex: '1 0 75%',
    marginRight: '2rem',
  },
  buttonWrapper: {
    ...STYLE_CONSTANTS.FLEX.SPACE_AROUND,
  },
  createChatAttachmentButton: {
    ...STYLE_CONSTANTS.DISPLAY.GENERIC_DISPLAY,
    ...STYLE_CONSTANTS.FLEX.COLUMN,
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: `${theme.palette.secondary.main}25`,
    flex: '1 0 calc(25% - 2rem)',
    padding: '1.3rem 2.7rem',
    fontWeight: 600,
    fontSize: '1.5rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    border: `.1rem solid ${theme.palette.secondary.main}`,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      border: `.1rem solid ${theme.palette.primary.main}`,
    },

    '& > svg': {
      fontSize: '5rem',
    },
  },
  button: {
    '&.MuiButton-root': {
      maxHeight: '2rem',
      fontWeight: 600,
      maxWidth: '2rem',
      padding: '1.3rem 3.5rem',
      margin: 'auto 0',

      '&:disabled': {
        opacity: 0.5,
      },
    },
  },
  submitButton: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.secondary.main,
      border: `.1rem solid ${theme.palette.secondary.main}`,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        border: `.1rem solid ${theme.palette.primary.main}`,
      },
    },
  },
  resetButton: {
    '&.MuiButton-root': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      border: `.1rem solid ${theme.palette.primary.main}`,

      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        border: `.1rem solid ${theme.palette.secondary.main}`,
      },
    },
  },
}));
