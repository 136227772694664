import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import { Box, Button, TextField, Typography } from '@mui/material';
import FileIcon from '@mui/icons-material/InsertDriveFile';

import { notificationActions } from '../../../store/reducers/notificationReducer';
import { ROUTE_PATHS } from '../../routes/route-paths';
import FieldWrapper from '../../shared/fieldWrapper/FieldWrapper';
import Loader from '../../shared/components/loader/LoaderIcon';
import { TChatAttachmentCreateForm } from '../../types/chat-attachment-types';

import { useStyles } from './chat-attachment-create-styles';
import { chatAttachmentManagementApi } from '../../api/chat-attachment-management-api';

const fileTypesAccepted = [
  'csv',
  'doc',
  'docx',
  'jpeg',
  'jpg',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'txt',
  'wav',
  'xls',
  'xlsx',
];
const ChatAttachmentCreate: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    filterWrapper,
    dateRangePicker,
    buttonWrapper,
    button,
    resetButton,
    submitButton,
    header,
    browseButton,
  } = useStyles();

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm<TChatAttachmentCreateForm>({
    defaultValues: {
      title: '',
      description: '',
    },
  });

  const [chosenFiles, setChosenFiles] = useState<File[] | null>(null);

  const [isUploadInProgress, setIsUploadInProgress] = useState(false);

  const onDrop = (acceptedFiles: File[]) => {
    setChosenFiles(acceptedFiles);
  };

  const onDropRejected = () => {
    dispatch(
      notificationActions.openNotification({
        isOpen: true,
        type: 'error',
        message: t('chatAttachments.dropRejected'),
      })
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    noClick: true,
    maxFiles: 1,
    maxSize: 20_971_520,
  });

  const handleSave = async (inputValues: TChatAttachmentCreateForm) => {
    if (chosenFiles === null || chosenFiles?.length === 0) {
      dispatch(
        notificationActions.openNotification({
          isOpen: true,
          type: 'error',
          message: t('chatAttachments.dropOrSelectFile'),
        })
      );
      return;
    }

    if (chosenFiles !== null) {
      if (chosenFiles[0].size > 20971520) {
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'error',
            message: t('chatAttachments.notification.fileCannotExceed'),
          })
        );
        return;
      } else if (chosenFiles[0].size === 0) {
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'error',
            message: t('chatAttachments.notification.emptyFile'),
          })
        );
        return;
      } else if (
        !fileTypesAccepted.includes(
          chosenFiles[0].name
            .split('.')
            [chosenFiles[0].name.split('.').length - 1].toLowerCase()
        )
      ) {
        dispatch(
          notificationActions.openNotification({
            isOpen: true,
            type: 'error',
            message: t('chatAttachments.notification.invalidType'),
          })
        );
        return;
      }
    }

    try {
      setIsUploadInProgress(true);
      await chatAttachmentManagementApi.createNewAttachment({
        title: inputValues.title,
        description: inputValues.description,
        file: chosenFiles[0],
      });
      history.push(
        ROUTE_PATHS.chatAttachmentManagement.chatAttachmentManagement
      );
      dispatch(
        notificationActions.openNotification({
          isOpen: true,
          type: 'success',
          message: t('chatAttachments.attachmentCreatedSuccessfully'),
        })
      );
    } catch (error: any) {
      dispatch(
        notificationActions.openNotification({
          isOpen: true,
          type: 'error',
          message: error?.response
            ? error?.response.data.message
            : t('chatAttachments.somethingWentWrongCreatingTheAttachment'),
        })
      );
    } finally {
      setIsUploadInProgress(false);
    }
  };

  return (
    <Box
      className={filterWrapper}
      onSubmit={handleSubmit(handleSave)}
      component="form"
    >
      <Typography variant="h1" color="primary" className={header}>
        {t('chatAttachments.createChatAttachment')}
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <FieldWrapper label="chatAttachments.title" percent={30}>
          <TextField
            variant="outlined"
            {...register('title', {
              required: `${t('error.required')}`,
              maxLength: {
                value: 100,
                message: `${t('error.validation.maxLength', { count: 100 })}`,
              },
            })}
            error={!!errors.title?.message}
            helperText={errors.title ? errors.title.message : ' '}
            placeholder={errors ? errors.title?.message : undefined}
            autoComplete="off"
          />
        </FieldWrapper>

        <FieldWrapper label="chatAttachments.description" percent={30}>
          <TextField
            variant="outlined"
            {...register('description', {
              required: `${t('error.required')}`,
              maxLength: {
                value: 100,
                message: `${t('error.validation.maxLength', { count: 100 })}`,
              },
            })}
            error={!!errors.description?.message}
            helperText={errors.description ? errors.description.message : ' '}
            placeholder={errors ? errors.description?.message : undefined}
            autoComplete="off"
          />
        </FieldWrapper>
      </Box>

      <Box sx={{ padding: 2, width: '100%' }}>
        <Box
          {...getRootProps()}
          sx={{
            border: '1px solid lightgrey',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 3,
            position: 'relative',
            gap: 3,
            ...(isDragActive && {
              backgroundColor: 'lightgrey',
            }),
          }}
        >
          {isUploadInProgress && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#D3D3D3F0',
              }}
            >
              <Loader />
            </Box>
          )}
          <FileIcon
            sx={{
              width: 60,
              height: 60,
            }}
          />
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 'bold',
            }}
          >
            {t('chatAttachments.dropOrSelectFile')}
          </Typography>
          <Typography sx={{ fontSize: 16 }}>
            {t('chatAttachments.fileMustBeUpToLimit')}
          </Typography>
          <Typography sx={{ fontSize: 16 }}>
            {chosenFiles && chosenFiles.map((item) => item.name)?.join(', ')}
          </Typography>
          <label className={browseButton}>
            {t('chatAttachments.browseChatAttachment')}
            <input
              type="file"
              hidden
              {...getInputProps()}
              accept="text/plain,application/pdf,image/jpeg,image/png,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,audio/wav,audio/x-wav,audio/vnd.wave,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv"
              multiple={false}
            />
          </label>
        </Box>
      </Box>

      <Box>
        <FieldWrapper percent={30}>
          <Box className={buttonWrapper}>
            <Button
              type="submit"
              classes={{
                root: button,
                text: submitButton,
              }}
              disabled={isUploadInProgress}
            >
              {t('button.save')}
            </Button>
            <Button
              classes={{
                root: button,
                text: resetButton,
              }}
              onClick={() =>
                history.push(
                  ROUTE_PATHS.chatAttachmentManagement.chatAttachmentManagement
                )
              }
              disabled={isUploadInProgress}
            >
              {t('button.discard')}
            </Button>
          </Box>
        </FieldWrapper>
      </Box>
    </Box>
  );
};

export default ChatAttachmentCreate;
