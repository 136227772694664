import React, { useState } from 'react';

import { Toolbar, IconButton, Box } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Header } from './components/header';

import NavbarList from './navbar-list';

import { ReactComponent as Logo } from '../../../assets/images/webhelp-logo-dark.svg';

import { AppBar, Drawer, useStyles } from './side-nav-style';

const SideNav = ({
  children,
}: React.PropsWithChildren<Record<never, never>>) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box className={classes.mainWrapper}>
      <AppBar sx={{ height: '45px' }} position="fixed">
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            id="menu-icon"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={classes.menuIcon}
          >
            <Logo className={classes.logo} />
            {open ? (
              <ChevronLeftIcon color="primary" />
            ) : (
              <ChevronRightIcon color="primary" />
            )}
          </IconButton>
          <Header />
        </Toolbar>
      </AppBar>
      <Drawer open={open} variant="permanent">
        <NavbarList open={open} />
      </Drawer>
      <Box component="main" className={classes.content}>
        {children}
      </Box>
    </Box>
  );
};

export default SideNav;
