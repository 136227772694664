import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { STYLE_CONSTANTS } from '../../../assets/styles/defaultTheme/constants';

interface IProps {
  percent: number;
}

export const useStyles = makeStyles<Theme, IProps>(() => ({
  wrapper: (props) => ({
    ...STYLE_CONSTANTS.FLEX.COLUMN,
    justifyContent: 'space-around',
    margin: '1rem',
    textAlign: 'left',
    flex: `1 0 ${props.percent}%`,
  }),
}));
