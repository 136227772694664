import { AxiosPromise } from 'axios';
import { axiosInstance } from './axios-client';

import apiPaths from './api-paths';
import { THierarchyGroupWithChildren } from '../types/connect-types';

const {
  connect: { base, hierarchyGroups },
} = apiPaths;

export const connectApi: {
  getHierarchyGroups: () => AxiosPromise<THierarchyGroupWithChildren>;
} = {
  getHierarchyGroups: () => axiosInstance.get(base + hierarchyGroups),
};
