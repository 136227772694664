import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TUserAssociationFilterRequest,
  TUserAssociationFilterReducerState,
} from '../../app/types/user-association-types';
import { TSortList } from '../../app/types/general-types';

export const userAssociationFilterInitialState: TUserAssociationFilterReducerState =
  {
    filters: {},
    forceRefresh: true,
    page: 0,
    sortingOptions: { direction: 'ASC', property: 'MY_CONNECT_USERNAME' },
  };

const userAssociationFilterReducer = createSlice({
  name: 'userAssociationfilterReducer',
  initialState: userAssociationFilterInitialState,
  reducers: {
    setFilters: (
      state,
      { payload }: PayloadAction<Partial<TUserAssociationFilterRequest>>
    ) => {
      state.filters = payload;
      state.forceRefresh = !state.forceRefresh;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSorting: (state, { payload }: PayloadAction<TSortList>) => {
      state.sortingOptions = payload;
      state.forceRefresh = !state.forceRefresh;
      state.page = 0;
    },
  },
});

export const userAssociationFilterActions =
  userAssociationFilterReducer.actions;

export default userAssociationFilterReducer.reducer;
