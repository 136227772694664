import { TIdValue, TSelectOption } from '../../types/general-types';

export const prepareSelectOptions = (queueList: TIdValue[]): TSelectOption[] =>
  queueList.map((el) => ({ label: el.value, value: el.id }));

export const prepareSelectOptionsForHolidayCalendar = (queueList: TIdValue[]): TSelectOption[] =>
  queueList.map((el) => ({ label: el.value, value: el.value }));

export const prepareSelectOptionsForAdmins = (adminsList: string[]): TSelectOption[] =>
  adminsList.map((el) => ({ label: el, value: el }));
  