import { makeStyles } from '@mui/styles';
import { Theme, styled, CSSObject } from '@mui/material';

import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 350;

const openedMixin = (theme: Theme): CSSObject => ({
  paddingTop: theme.spacing(6),
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  paddingTop: theme.spacing(6),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(10),
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(12),
  },
});

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.common.white,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const useStyles = makeStyles((theme: Theme) => ({
  mainWrapper: {
    display: 'flex',
    height: 'calc(100vh - 45px)',
  },
  toolbar: {
    '&.MuiToolbar-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 .7rem',
      minHeight: '45px',
    },
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(8),
    backgroundColor: `${theme.palette.common.black}08`,
    height: 'calc(100vh)',
    width: '80%',
    overflow: 'auto',
  },
  logo: {
    width: '5rem',
    height: '2rem',
  },
  menuIcon: {
    '&.MuiIconButton-root': {
      padding: '.2rem 0',
      borderRadius: '.3rem',

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}33`,
      },
    },
  },
  headerButton: {
    '&.MuiButton-root': {

      '&:hover': {
        backgroundColor: `${theme.palette.secondary.main}33`,
      },
    },
  },
  listWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: 0,
    borderRadius: '.5rem',
    zIndex: 10,
    boxShadow: `0 0.3rem 0.6rem ${theme.palette.common.black}33`,

    '& > li': {
      padding: '.5rem 2rem',
      textAlign: 'center',

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}20`,
      },
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: `1 0 90%`,
  },
}));
