import { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Loader from '../shared/components/loader/LoaderIcon';
import { useConfig } from '../hooks/useConfig';

import { ROUTE_PATHS } from './route-paths';
import { connectApi } from '../api/connect-api';
import { hierarchyGroupsActions } from '../../store/reducers/hierarchy-groups-reducer';
import ChatAttachmentManagement from '../pages/chat-attachment-management';
import ChatAttachmentCreate from '../components/chat-attachment-management/chat-attachment-create';

const Dashboard = lazy(
  () => import(/* webpackChunkName: 'dashboard' */ '../pages/Dashboard')
);

const UserAssociation = lazy(
  () =>
    import(
      /* webpackChunkName: 'user-association' */ '../pages/user-association'
    )
);

const Forms = lazy(
  () => import(/* webpackChunkName: 'forms' */ '../pages/Forms')
);

const FormBuilder = lazy(
  () =>
    import(
      /* webpackChunkName: 'formBuilder' */ '../components/forms/formBuilder/FormBuilder'
    )
);
const FormView = lazy(
  () =>
    import(
      /* webpackChunkName: 'viewForm' */ '../components/forms/formView/FormView'
    )
);

const ScreenPopUp = lazy(
  () => import(/* webpackChunkName: 'screenPopUp' */ '../pages/ScreenPopUp')
);

const ScreenPopupEdit = lazy(
  () =>
    import(
      /* webpackChunkName: 'screenPopupEdit' */ '../components/screenPopUp/edit/screen-pop-up-create-or-edit'
    )
);

const AgentStats = lazy(
  () => import(/* webpackChunkName: 'agentStats' */ '../pages/AgentStatsPage')
);

const HolidayCalendar = lazy(
  () =>
    import(
      /* webpackChunkName: 'holidayCalendar' */ '../pages/holiday-calendar'
    )
);

const HolidayCalendarEdit = lazy(
  () =>
    import(
      /* webpackChunkName: 'holidayCalendarEdit' */ '../components/holiday-calendar/holiday-calendar-edit'
    )
);

const HolidayCalendarCreate = lazy(
  () =>
    import(
      /* webpackChunkName: 'holidayCalendarCreate' */ '../components/holiday-calendar/holiday-calendar-create'
    )
);

const GraphConfiguration = lazy(
  () =>
    import(
      /* webpackChunkName: 'graphConfiguration' */ '../pages/graph-configuration'
    )
);

const OutboundCampaign = lazy(
  () =>
    import(
      /* webpackChunkName: 'outboundCampaign' */ '../pages/outbound-campaign-manager'
    )
);

const OutboundCampaignUpload = lazy(
  () =>
    import(
      /* webpackChunkName: 'outboundCampaignUpload' */ '../components/outbound-campaign-manager/outbound-campaign-manager-upload'
    )
);

const OutboundCampaignFileSetup = lazy(
  () =>
    import(
      /* webpackChunkName: 'outboundCampaignFileSetup' */ '../components/outbound-campaign-manager/outbound-campaign-manager-file-setup'
    )
);

const OutboundCampaignEdit = lazy(
  () =>
    import(
      /* webpackChunkName: 'outboundCampaignEdit' */ '../components/outbound-campaign-manager/outbound-campaign-edit'
    )
);

const MainRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    connectApi.getHierarchyGroups().then(({ data }) => {
      dispatch(
        hierarchyGroupsActions.setHierarchyGroups({
          childGroups: data.childGroups,
          groupName: data.groupName,
          hierarchyLevel: data.hierarchyLevel,
          id: data.id,
        })
      );
    });
  }, []);

  const { env } = useConfig();

  if (!env) {
    return null;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {env?.enabledFeatures?.DASHBOARD && (
          <Route exact path={ROUTE_PATHS.dashboard} component={Dashboard} />
        )}
        {env?.enabledFeatures?.USER_ASSOCIATION && (
          <Route
            exact
            path={ROUTE_PATHS.userAssociation}
            component={UserAssociation}
          />
        )}
        <Route exact path={ROUTE_PATHS.forms.list} component={Forms} />
        <Route
          path={ROUTE_PATHS.forms.formBuilder.base}
          component={FormBuilder}
        />
        <Route path={ROUTE_PATHS.forms.viewForm} component={FormView} />
        <Route
          path={ROUTE_PATHS.screenPopUp.edit}
          render={() => <ScreenPopupEdit />}
        />
        <Route
          path={ROUTE_PATHS.screenPopUp.create}
          render={() => <ScreenPopupEdit />}
        />
        <Route
          exact
          path={ROUTE_PATHS.screenPopUp.screenPopUp}
          render={() => (
            <Redirect to={ROUTE_PATHS.screenPopUp.screenPopUpCta} />
          )}
        />
        <Route
          path={ROUTE_PATHS.screenPopUp.screenPopUp}
          component={ScreenPopUp}
        />
        <Route
          path={ROUTE_PATHS.agentStats.agentStats}
          component={AgentStats}
        />
        {env?.enabledFeatures?.HOLIDAY_CALENDAR && (
          <Route
            path={ROUTE_PATHS.holidayCalendar.holidayCalendar}
            render={() => <HolidayCalendar />}
          />
        )}
        {env?.enabledFeatures?.HOLIDAY_CALENDAR && (
          <Route
            path={ROUTE_PATHS.holidayCalendar.edit}
            render={() => <HolidayCalendarEdit />}
          />
        )}
        {env?.enabledFeatures?.HOLIDAY_CALENDAR && (
          <Route
            path={ROUTE_PATHS.holidayCalendar.create}
            render={() => <HolidayCalendarCreate />}
          />
        )}
        {env?.enabledFeatures?.GRAPH_CONFIGURATION && (
          <Route
            path={ROUTE_PATHS.graphConfiguration.graphConfiguration}
            render={() => <GraphConfiguration />}
          />
        )}
        {env?.enabledFeatures?.CHAT_ATTACHMENT_MANAGEMENT && (
          <Route
            path={ROUTE_PATHS.chatAttachmentManagement.chatAttachmentManagement}
            render={() => <ChatAttachmentManagement />}
            exact
          />
        )}
        {env?.enabledFeatures?.CHAT_ATTACHMENT_MANAGEMENT && (
          <Route
            path={ROUTE_PATHS.chatAttachmentManagement.create}
            render={() => <ChatAttachmentCreate />}
          />
        )}
        {env?.enabledFeatures?.OUTBOUND_CAMPAIGN && (
          <Route
            path={ROUTE_PATHS.outboundCampaign.outboundCampaign}
            render={() => <OutboundCampaign />}
            exact
          />
        )}
        {env?.enabledFeatures?.OUTBOUND_CAMPAIGN && (
          <Route
            path={ROUTE_PATHS.outboundCampaign.upload}
            render={() => <OutboundCampaignUpload />}
            exact
          />
        )}
        {env?.enabledFeatures?.OUTBOUND_CAMPAIGN && (
          <Route
            path={ROUTE_PATHS.outboundCampaign.fileSetup}
            render={() => <OutboundCampaignFileSetup />}
            exact
          />
        )}
        {
          <Route
            path={ROUTE_PATHS.outboundCampaign.edit}
            render={() => <OutboundCampaignEdit />}
          />
        }
        <Redirect
          to={
            env?.enabledFeatures?.DASHBOARD
              ? ROUTE_PATHS.dashboard
              : ROUTE_PATHS.forms.list
          }
        />
      </Switch>
    </Suspense>
  );
};

export default MainRoutes;
