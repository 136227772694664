export const downloadFile = async (url: string, name: string) => {
  const element = document.createElement('a');

  element.style.display = 'none';
  element.setAttribute('href', url);
  element.setAttribute('download', name);
  element.setAttribute('target', '_blank');
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadFileBlobContent = async (
  blobContent: Blob,
  name: string,
  type: string
) => {
  const blob = new Blob([blobContent], { type });
  const element = document.createElement('a');

  element.style.display = 'none';
  element.setAttribute(
    'href',
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob)
  );
  element.setAttribute('download', name);
  element.setAttribute('target', '_blank');
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
