export const themeConstants = {
  COLOR_PRIMARY: '#7fd7c3',
  COLOR_PRIMARY_DARKER: '#63b4a1',
  COLOR_SECONDARY: '#297380',
  COLOR_SECONDARY_DARKER: '#004850',
  COLOR_THIRD: '#00bed2',
  COLOR_ERROR: '#b03661',
  COLOR_ERROR_LIGHTER: '#cc3262',
  COLOR_BLACK: '#000000',
  COLOR_WHITE: '#ffffff',
  COLOR_GREY: '#c0c0c0',
  COLOR_TEXT: '#1d4851',
  COLOR_WARNING: '#f7c900',
  COLOR_WARNING_DARKER: '#D27800',
  COLOR_INFO: '#5EBDD4',

  PRIMARY_FONT: ['Montserrat', 'sans-serif'].join(','),
} as const;

export const STYLE_CONSTANTS = {
  DISPLAY: {
    GENERIC_DISPLAY: {
      backgroundColor: themeConstants.COLOR_WHITE,
      boxShadow: '0 .3rem .6rem rgba(0,0,0,0.2)',
      borderRadius: '0.5rem',
    },
  },
  FLEX: {
    SPACE_BETWEEN: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    SPACE_AROUND: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    SPACE_EVENLY: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    FLEX_WRAP: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    COLUMN: {
      display: 'flex',
      flexDirection: 'column',
    },
    VERTICAL_CENTER: {
      display: 'flex',
      alignItems: 'center',
    },
    TOTAL_CENTER: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    HORIZONTAL_CENTER: {
      display: 'flex',
      justifyContent: 'center',
    },
    HORIZONTAL_RIGHT: {
      display: 'flex',
      justifyContent: 'flex-end',
    }
  },
} as const;
