import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import { Box, Typography } from '@mui/material';

import { authSelectors } from '../../../../store/selectors';
import { usersApi } from '../../../api/users-api';
import { languageActions } from '../../../../store/reducers/language-reducer';
import HeaderDropdown from './header-dropdown';
import LanguageSelect from '../../language-select/language-select';
import { useConfig } from '../../../hooks/useConfig';

import { useStyles } from '../side-nav-style';

export const Header: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userInfo = useSelector(authSelectors.getUserInfo);

  const { env } = useConfig();

  useEffect(() => {
    usersApi.getLanguage().then(({ data }) => {
      if (!data) {
        data = 'us';
      }
      dispatch(languageActions.setLanguage(data));
      i18next.changeLanguage(data);
    });
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {env?.enabledFeatures?.LANGUAGE_SELECTOR && (
          <Box
            sx={{
              height: '45px',
            }}
          >
            <LanguageSelect />
          </Box>
        )}
        <Typography sx={{ margin: 'auto 0', fontWeight: 500 }} color="primary">
          {userInfo ? userInfo.firstName + ' ' + userInfo.lastName : ''}
        </Typography>
        <HeaderDropdown />
      </Box>
    </Box>
  );
};
