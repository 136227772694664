import { FC } from 'react';

import { TableCell, TableRow } from '@mui/material';

import { TListElement } from '../../types/tableTypes';

interface IProps {
  cell: TListElement;
  onClick?: (
    event?: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id?: string
  ) => void;
  id?: string;
  index?: number;
}

const ListRow: FC<IProps> = ({
  cell,
  onClick = () => {
    return;
  },
  id,
  index,
}) => (
  <TableRow id={id} onClick={(event) => onClick(event, id)}>
    {Object.keys(cell).map((key, cellIndex) => (
      <TableCell
        key={`${id}${key}`}
        data-test-id={
          index !== undefined &&
          `form_listing_row_${index + 1}_table_cell_${cellIndex + 1}`
        }
      >
        {cell[key]}
      </TableCell>
    ))}
  </TableRow>
);

export default ListRow;
