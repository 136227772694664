import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TSortList } from "../../app/types/general-types";
import { THolidayCalendarFilter, THolidayCalendarReducerState } from "../../app/types/holiday-calendar-types";


function getFirstDayOfMonth(year: any, month: any) {
  return new Date(year, month, 1);
}
const today = new Date();

const firstDayCurrentMonth = getFirstDayOfMonth(
  today.getFullYear(),
  today.getMonth()
);
const lastDayCurrentMonth = new Date(
  today.getFullYear(),
  today.getMonth() + 1,
  0
);

export const holidayCalendarInitialState: THolidayCalendarReducerState = {
  filters: {
    dateFrom: firstDayCurrentMonth,
    dateTo: lastDayCurrentMonth,
  },
  forceRefresh: true,
  page: 0,
  sortingOptions: { direction: 'ASC', property: 'END_DATE' },
};

const holidayCalendarReducer = createSlice({
  name: 'holidayCalendarReducer',
  initialState: holidayCalendarInitialState,
  reducers: {
    setFilters: (state, { payload }: PayloadAction<Partial<THolidayCalendarFilter>>) => {
      state.filters = payload;
    },
    setSorting: (state, { payload }: PayloadAction<TSortList>) => {
      state.sortingOptions = payload;
      state.forceRefresh = !state.forceRefresh;
      state.page = 0;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
  }
});

export const holidayCalendarActions = holidayCalendarReducer.actions;

export default holidayCalendarReducer.reducer;
