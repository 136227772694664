import React, { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

import useSearchChatAttachments from '../../hooks/chat-attachment-management/use-search-chat-attachments';

import ListHeader from '../list/ListHeader';
import ListBodyWrapper from '../list/ListBodyWrapper';
import ListRow from '../list/ListRow';

import { TListElement } from '../../types/tableTypes';
import { chatAttachmentManagementFiltersSelectors } from '../../../store/selectors';
import { formatDate } from '../../../utils/dateUtils';
import { sortOnClick } from '../../../utils/sortUtils';
import { chatAttachmentFilterActions } from '../../../store/reducers/chat-attachment-filter-reducer';
import { modalReducerActions } from '../../../store/reducers/modalReducer';
import { CHAT_ATTACHMENT_LIST_HEADER } from './constants';
import ChatAttachmentDeleteModal from './chat-attachment-delete-modal';

import { chatAttachmentManagementApi } from '../../api/chat-attachment-management-api';
import { downloadFile } from '../../../utils/download-utils';

import { useStyles } from './chat-attachment-listing-styles';

const ChatAttachmentListing = () => {
  const [list, setList] = useState<TListElement[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const { chatAttachments, totalNumberOfPages, isLoading, refetch } =
    useSearchChatAttachments();
  const { page, sortingOptions } = useSelector(
    chatAttachmentManagementFiltersSelectors.getState
  );

  const downloadFileByFileName = async (fileName: string) => {
    try {
      const { data: fileURL } =
        await chatAttachmentManagementApi.getPresignedUrl({
          fileName,
        });
      downloadFile(fileURL, fileName);
    } catch (err) {
      console.error(err);
    }
  };

  const [chatAttachmentToDeleteName, setChatAttachmentToDeleteName] =
    useState('');

  useEffect(() => {
    const copy: TListElement[] = chatAttachments.map((el) => ({
      id: el.name,
      name: (
        <Tooltip
          disableFocusListener
          disableTouchListener
          placement="bottom-start"
          title={el.name}
        >
          <span style={{ textOverflow: 'ellipsis' }}>{el.name}</span>
        </Tooltip>
      ),
      title: el.title,
      size: el.size / 1000 + ' kB',
      uploadDate: formatDate(new Date(el.uploadDate)),
      userName: el.userName,
      description: el.description,
      actions: (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Tooltip
            disableFocusListener
            disableTouchListener
            placement="bottom"
            title={`${t('chatAttachments.actions.download')}`}
          >
            <Button
              onClick={() => {
                downloadFileByFileName(el.name);
              }}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
          <Tooltip
            disableFocusListener
            disableTouchListener
            placement="bottom"
            title={`${t('chatAttachments.actions.delete')}`}
          >
            <Button
              onClick={() => {
                setChatAttachmentToDeleteName(el.name);
                dispatch(
                  modalReducerActions.setDeleteChatAttachment({
                    open: true,
                    data: {
                      id: el.id,
                    },
                  })
                );
              }}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        </Box>
      ),
    }));
    setList(copy);
  }, [chatAttachments]);

  const handleRowClick = (id: any) => {
    // history.push(generatePath(ROUTE_PATHS.forms.viewForm, { formId }));
  };

  const handleSort = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    property: string
  ) => {
    dispatch(
      chatAttachmentFilterActions.setSorting(
        sortOnClick(sortingOptions, property)
      )
    );
  };

  return (
    <>
      <ChatAttachmentDeleteModal
        attachmentName={chatAttachmentToDeleteName}
        onSuccessfulDeletion={() => {
          refetch(false);
        }}
      />
      <Box className={classes.listWrapper}>
        <TableContainer>
          <Typography variant="h1" className={classes.header}>
            {t('chatAttachments.list')}
          </Typography>
          <Table
            classes={{
              root: classes.table,
            }}
          >
            <ListHeader
              headers={CHAT_ATTACHMENT_LIST_HEADER}
              order={sortingOptions.direction === 'ASC' ? 'asc' : 'desc'}
              orderBy={sortingOptions.property}
              handleSort={handleSort}
            />
            <TableBody>
              <ListBodyWrapper
                noResult={list?.length === 0}
                length={CHAT_ATTACHMENT_LIST_HEADER.length}
                isLoading={isLoading}
              >
                {list.map(({ id, ...el }) => (
                  <ListRow
                    id={id}
                    key={id}
                    cell={el}
                    onClick={() => handleRowClick(id)}
                  />
                ))}
              </ListBodyWrapper>
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          className={classes.pagination}
          count={totalNumberOfPages}
          page={page + 1}
          onChange={(_, page) =>
            dispatch(chatAttachmentFilterActions.setPage(page - 1))
          }
          shape="rounded"
          boundaryCount={2}
          siblingCount={1}
          color="primary"
        />
      </Box>
    </>
  );
};

export default ChatAttachmentListing;
