export default {
  auth: {
    redirectLogin: '/saml/login',
    login: '/login',
    logout: '/logout',
  },
  form: {
    forms: '/forms',
    details: '/details',
    assignableQueues: '/assignable-queues',
    names: '/names',
    validate: '/validate',
    questionTypes: '/question-types',
    search: '/search',
    statuses: '/statuses',
    types: '/types',
    queues: '/queues',
  },
  queue: {
    queues: '/queues',
    names: '/names',
    active: '/active',
  },
  screenPopUp: {
    urlConfiguration: '/url-configuration',
    getDetailedPopUpURL: (id: string) => `/url-configuration/${id}`,
    parameters: '/parameters',
    predefinedOptions: '/predefined-options',
  },
  agentStats: {
    agentStats: '/agent-stats',
    statusIntervals: '/status-intervals',
    statuses: '/statuses',
    admin: '/admin',
    hierarchies: '/hierarchies',
  },
  userAssociation: {
    base: '/user-association',
    create: '/create',
    search: '/search',
    usernames: '/usernames',
    update: '/update',
    getDetailedUserAssociationURL: (id: string) => `/user-association/${id}`,
  },
  dashboard: {
    base: '/admin/dashboards',
    timeSpentOnCallsByDay: '/time_spent_on_calls',
    timeSpentOnCallsByHour: '/time_spent_on_calls_by_hour',
    callVolumesByDay: '/call_volumes',
    callVolumesByHour: '/call_volumes_by_hour',
    ahtByDay: '/aht',
    ahtByHour: '/aht_by_hour',
    fcrByDay: '/fcr_by_day',
  },
  connect: {
    base: '/connect',
    hierarchyGroups: '/hierarchy-groups',
  },
  users: {
    users: '/users',
    language: '/language',
    admins: '/admins',
  },
  admin: {
    admin: '/admin',
    holidayCalendar: '/holiday_calendar',
    search: '/search',
    exists: '/exists',
  },
  graphConfiguration: {
    base: '/admin/graph-configuration',
    search: '/search',
    parameters: '/parameters',
  },
  chatAttachments: {
    base: '/attachments',
    files: '/files',
    remove: '/remove',
  },
  outboundCampaign: {
    outboundCampaign: '/outbound-campaign',
    files: '/files',
    fileUpload: '/file-upload',
    updateQueue: '/file-update-queue',
    download: '/download',
    availableFileSettings: '/available-file-settings',
    fileSettings: '/file-settings',
  },
} as const;
