import { FC } from 'react';
import LoaderIcon from './LoaderIcon';

interface IProps {
  hasData?: boolean;
  loading?: boolean;
}

const Loader: FC<IProps> = ({ children, hasData = true, loading = false }) => (
  <>{loading ? <LoaderIcon /> : children}</>
);

export default Loader;
