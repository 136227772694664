import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box } from '@mui/system';
import DescriptionIcon from '@mui/icons-material/Description';

import { ROUTE_PATHS } from '../../routes/route-paths';

import { useStyles } from './chat-attachment-filters-styles';

const CreateChatAttachment = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Box
      className={classes.createChatAttachmentButton}
      onClick={() => history.push(ROUTE_PATHS.chatAttachmentManagement.create)}
    >
      <DescriptionIcon />
      <Box sx={{ textAlign: 'center' }} component="span">
        {t('button.createChatAttachment')}
      </Box>
    </Box>
  );
};

export default CreateChatAttachment;
